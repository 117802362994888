import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { first } from 'rxjs/operators';
import { CitationService } from '../_services';

import { MatDialog } from '@angular/material/dialog';
import { CitationInvoiceDialog } from '../_shared/citation-invoice/citation-invoice.dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-citation-control',
  templateUrl: './citation-control.component.html',
  styleUrls: ['./citation-control.component.scss']
})
export class CitationControlComponent implements OnInit, AfterViewChecked {
  loading = false;
  data: any = {};
  ID;
  penalties: any;
  total: number; // from penalties com
  voidReasonsList: [] = [];
  vat = 0;
  files;
  isAttachsHidden: boolean = true;
  paymentMethodForm!: FormGroup
  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _citationService: CitationService,
    private _dialog: MatDialog,
    private changeDetector: ChangeDetectorRef,
    private fb: FormBuilder

  ) {
    this.ID = this._activatedRoute.snapshot.params.id;
    this._loadCitation(this.ID);
    this.paymentMethodForm = this.fb.group({
      paymentMethod: ['CASH', Validators.required]
    });

  }

  ngOnInit(): void {
    // console.log(`CitationControlComponent ID ${this.ID}, data  `, this.data);
    this._getVoidReasonsList();
    this._loadVat();
  }
  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }
  private _loadCitation(ids) {
    this._citationService.getDetails(ids)
      .pipe(first())
      .subscribe(
        resp => {
          this.data = resp.payload;
        },
        error => {
          console.log(" err ===== ", error);
        });
  }

  getPenalities($event) {
    this.penalties = $event;
    const isVoided = this.penalties.citationRequests[0].penaltiesRequestList.filter(item => item.penActionType === "Voided");
    this.isAttachsHidden = (isVoided.length) ? false : true;
    return $event;
  }

  getFiles($event) {
    this.files = $event;
  }

  getTotal($event) {
    this.total = $event;
    return $event;
  }

  onApply() {
    this.penalties.citationRequests.map((citationRequest) => {
      citationRequest.penaltiesRequestList.map((penaltiesRequest) => {
        return (penaltiesRequest.penActionType == "Settled") ? penaltiesRequest.comment = "Settle from portal web" : '';
      });
    });

    const paymentMethod = this.paymentMethodForm.get('paymentMethod')?.value;
    this.penalties.settlementChannel = "ENFORCEMENT_WEB";
    this.penalties.paymentMethod = paymentMethod;
    console.log('penalities', this.penalties);
    

    // console.log("citation-control onApply this.penalties 1", this.penalties);

    this._citationService.payCitation(this.penalties).pipe(first())
      .subscribe(
        data => {
          this.loading = false;
          if (data["payload"].voided && (this.files !== undefined && this.files.length > 0)) { // if all is voided not show print and redirect to citation list
            const formData = new FormData();
            this.files.map(file => {
              formData.append('files', file);
            });

            this._citationService.postFiles(this.ID, formData).pipe(first())
              .subscribe(
                data => {
                  console.log("update payCitation  data =========", data);
                  this.loading = false;
                  this._router.navigateByUrl(`/enforcement-citations`);
                },
                error => {
                  console.log("update payCitation err ===== ", error);
                  this.loading = false;
                });
          } else {
            const dialogRefInvoice = this._dialog.open(CitationInvoiceDialog, {
              panelClass: 'custom-mat-show-image-dialog-container',
              width: '820px',
              // height: '500px',
              data: data["payload"],
              disableClose: true
            });

            dialogRefInvoice.afterClosed().subscribe(result => {
              console.log("dialogRefInvoice result", result);
              this._router.navigateByUrl(`/enforcement-citations`);
            });
          }

        },
        error => {
          console.log("update payCitation err ===== ", error);
          this.loading = false;
        });
  }

  onCancel() {
    this._router.navigateByUrl(`/enforcement-citations`);
  }

  private _getVoidReasonsList() {
    this._citationService.getVoidReasons().subscribe(res => {
      this.voidReasonsList = res.payload;
    })
  }
  private _loadVat() {
    this._citationService.getVat()
      .pipe(first())
      .subscribe(
        resp => {
          this.vat = resp.payload / 100;
        },
        error => { });
  }
}
